import React from "react";
import { graphql } from "gatsby";
import { DataConnection, PageQueryProps } from "~/model";
import Seo from "~/components/Seo";
import ResearchList, {
  ResearchListArticleFileData,
} from "~/features/marketing/ResearchList";

type ResearchCategoryPageProps = PageQueryProps<
  {
    readonly allMarkdownRemark: DataConnection<ResearchListArticleFileData>;
  },
  { readonly category: string }
>;

function ResearchCategoryPage({
  data: { allMarkdownRemark },
  pageContext: { category },
}: ResearchCategoryPageProps) {
  return (
    <ResearchList
      subHeading={`Posts in ${category}`}
      articles={allMarkdownRemark.edges.map(({ node }) => node)}
    />
  );
}

export const pageQuery = graphql`
  query ResearchCategoryPageQuery($category: String!) {
    allMarkdownRemark(
      filter: { frontmatter: { categories: { in: [$category] } } }
    ) {
      edges {
        node {
          ...ResearchList_article
        }
      }
    }
  }
`;

export function Head({ pageContext: { category } }: ResearchCategoryPageProps) {
  return <Seo title={`${category} - Research`} />;
}

export default ResearchCategoryPage;
