import React, { Fragment } from "react";
import { graphql, Link } from "gatsby";
import clsx from "clsx";
import { RemarkDocument } from "~/model";
import formatBlogDate from "~/utils/formatBlogDate";

type ResearchListArticleFileData = RemarkDocument<{
  readonly slug: string;
  readonly title: string;
  readonly summary: string;
  readonly date: string;
  readonly categories: ReadonlyArray<string>;
}>;

type ResearchListProps = {
  readonly subHeading?: string;
  readonly articles: ReadonlyArray<ResearchListArticleFileData>;
};

function ResearchList({ subHeading, articles }: ResearchListProps) {
  return (
    <div className="Content-outer">
      <section className="Intro">
        <div
          className="sqs-layout sqs-grid-12 columns-12 Intro-content"
          data-layout-label="Intro"
          data-type="block-field"
          id="5ac833a31ae6cf686a9a360b_intro"
        >
          <div className="row sqs-row">
            <div className="col sqs-col-12">
              <div
                className="sqs-block html-block sqs-block-html"
                data-block-type="2"
                id="block-e6f1eaaaa24f7ba047fd"
              >
                <div className="sqs-block-content">
                  <h2 style={{ textAlign: "center", whiteSpace: "pre-wrap" }}>
                    The Research Behind ProblemScape
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <main className="Main Main--blog-list">
        <section className="Main-content" data-content-field="main-content">
          {subHeading && (
            <header className="BlogList-filter">{subHeading}</header>
          )}
          <section
            className="BlogList BlogList--posts-excerpt sqs-blog-list clear"
            data-columns="2"
          >
            {articles.map(({ frontmatter: a }, i) => (
              <article
                id={a.slug}
                className={clsx(
                  `BlogList-item hentry author-vidya-raman post-type-text article-index-${
                    i + 1
                  }`,
                  { "has-categories": a.categories.length > 0 },
                )}
                data-item-id={a.slug}
              >
                <Link
                  to={`/research/${a.slug}/`}
                  className="BlogList-item-title"
                  data-content-field="title"
                >
                  {a.title}
                </Link>
                <div className="BlogList-item-excerpt">
                  <p>{a.summary}</p>
                  <Link
                    to={`/research/${a.slug}/`}
                    className="BlogList-item-readmore"
                  >
                    <span>Read More</span>
                  </Link>
                </div>
                <div className="Blog-meta BlogList-item-meta">
                  {a.categories.map((c, j) => (
                    <Fragment key={c}>
                      <Link
                        to={`/research/category/${encodeURIComponent(c)}/`}
                        className="Blog-meta-item-category"
                      >
                        {c}
                      </Link>
                      {j < a.categories.length - 1 && <>,&nbsp;</>}
                    </Fragment>
                  ))}
                  <time
                    className="Blog-meta-item Blog-meta-item--date"
                    dateTime={a.date}
                  >
                    {formatBlogDate(a.date)}
                  </time>
                </div>
              </article>
            ))}
          </section>
        </section>
      </main>
    </div>
  );
}

const researchListArticleFragment = graphql`
  fragment ResearchList_article on MarkdownRemark {
    frontmatter {
      slug
      title
      summary
      date
      categories
    }
  }
`;

export type { ResearchListArticleFileData };
export { researchListArticleFragment };
export default ResearchList;
